.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: 2px solid #21bf96 !important;
}

.ant-menu-horizontal > .ant-menu-item-selected a,
.ant-menu-horizontal > .ant-menu-item a:hover {
    color: #21bf96 !important;
}

.ant-modal-content {
    overflow: auto;
    border-radius: 1rem;
}

.app-layout {
    height: 100vh;
    overflow: auto;
}

.app-menu {
    display: flex;
    font-size: 17px;
    font-weight: 500;
    margin-left: 50px;
    width: 100%;
}

.app-header {
    position: fixed;
    z-index: 1;
    width: 100%;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Roboto, sans-serif;
    border-bottom: 2px solid rgba(0, 0, 0, 0.06);
    padding: 0 10px;
    box-shadow: 0 1px 10px rgb(151, 164, 175);
}

.ant-upload-list-picture-card .ant-upload-list-item{
    border: none;
}

.ant-upload-list-picture-card-container {
    display: inline-block;
    height: 100%;
    width: 100%;
    margin: 0;
}

.ant-upload.ant-upload-select-picture-card {
    margin-right: 0;
    margin-bottom: 0;
    border: none;
    height: 100%;
    width: 100%;
}

.app-footer {
    text-align: center;
}

.ant-upload-list {
    height: 100%;
}
.anticon-eye{
    display: none;
}
.app-text {
    display: block;
}

.account-modal {
    padding: 15px;
    font-size: 17px;
    font-weight: 500;
}

.account-card {
    margin-top: 10px;
    border-radius: 1rem;
    padding: 15px;
}

.account-select-icon {
    margin-right: 5px;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input{
    height: 100%;
}

.account-button {
    width: 100%;
    margin-top: 10px;
    border-radius: 0.5rem;
    font-size: 16px;
    font-weight: 500;
}

.address-copy {
    cursor: pointer;
}

.address-input-cross {
    cursor: pointer;
}

.chains-menu-item {
    display: flex;
    align-items: center;
    height: 42px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    padding: 0 10px;
}

.chains-button {
    display: flex;
    align-items: center;
    height: 42px;
    font-weight: 500;
    font-wamily: Roboto, sans-serif;
    font-size: 14px;
    padding: 0 10px;
    border: 2px solid rgb(231, 234, 243);
    border-radius: 12px;
}

.contract-card-name {
    width: 60%;
    box-shadow: 0 0.5rem 1.2rem rgb(189, 197, 209);
    border: 1px solid #e7eaf3;
    border-radius: 0.5rem;
}

.contract-card-events {
    width: 40%;
    box-shadow: 0 0.5rem 1.2rem rgb(189, 197, 209);
    border: 1px solid #e7eaf3;
    border-radius: 0.5rem;
}

.contract-card-transfer-events {
    margin-bottom: 20px;
}

.ant-select-item-option-content {
    white-space: break-spaces;
    word-break: break-word;
}

.contract-form-item-input {
    margin-bottom: 15px;
}

.contract-form-item {
    margin-bottom: 5px;
}

.contract-text {
    display: block;
}

.inchdex-text-price {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    color: #434343;
    margin-top: 8px;
    padding: 0 10px;
}

.inchdex-text-amount {
    font-weight: 600;
    color: #434343;
}

.inchdex-card-main {
    padding: 18px;
    width: 430px;
    box-shadow: 0 0.5rem 1.2rem rgb(189, 197, 209);
    border: 1px solid #e7eaf3;
    border-radius: 1rem;
    font-size: 16px;
    font-weight: 500;
}

.inchdex-card-secondary {
    border-radius: 1rem;
    padding: 0.8rem;
}

.ichdex-input {
    padding: 0;
    font-weight: 500;
    font-size: 23px;
    display: block;
    width: 100%;
    margin-left: -10px;
}

.ichdex-button-select {
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.6rem;
    padding: 5px 10px;
    font-weight: 500;
    font-size: 17px;
    gap: 7px;
    border: none;
}

.ichdex-button-swap {
    width: 100%;
    margin-top: 15px;
    border-radius: 0.6rem;
    height: 50px;
}

.ichdex-image {
    border-radius: 15px;
}

.nftbalance-card {
    width: 240;
    border: 2px solid #e7eaf3;
}

.nftbalance-image {
    height: 240px;
}

.tokenids-image {
    height: 115px;
    width: 115px;
    border-radius: 50%;
    positon: relative;
    margin-top: -80px;
    border: solid 4px white;
}

.tokenids-card {
    width: 240;
    border: 2px solid #e7eaf3;
}

.tokenids-image-nft {
    height: 240px;
}

.quickstart-card {
    box-shadow: 0 0.5rem 1.2rem rgb(189, 197, 209);
    border: 1px solid #e7eaf3;
    border-radius: 0.5rem;
}

.quickstart-card-connect {
    margin-top: 10px;
    box-shadow: 0 0.5rem 1.2rem rgb(189, 197, 209);
    border: 1px solid #e7eaf3;
    border-radius: 0.5rem;
}

.quickstart-text {
    font-size: 16px;
}

.quickstart-text-variables {
    display: block;
}

.quickstart-timeline {
    margin-bottom: -45px;
}

.asset-selector {
    width: 100%;
}

.asset-selector-image {
    border-radius: 15px;
}

.search-collections {
    width: 1000px;
    margin-left: 20px;
}

.transfer-button {
    width: 100%;
    margin-top: 25px;
}

.wallet-card {
    box-shadow: 0 0.5rem 1.2rem rgb(189, 197, 209);
    border: 1px solid #e7eaf3;
    border-radius: 1rem;
    width: 450px;
    font-size: 16px;
    font-weight: 500;
}

.quick-start {
    display: flex;
    justifycontent: center;
}

.notDraggable {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}